<template>
  <div class="m-pg-sub">
    <page-cover code="9" layer="rgba(0,0,0,.3)">
      <!-- <h2 class="m-page-title">{{$t('page.news.name')}}</h2> -->
      <!-- <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.news.name')}}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </page-cover>
    <div class="m-pg-sub_main m-w1200" v-loading="loading">
      <NewList>
        <NewListItem
          v-for="item in list"
          :key="item.Id"
          :model="item">
        </NewListItem>
      </NewList>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="page.index"
        :page-size.sync="page.size"
        :total="page.total"
        @current-change="onPageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
import util from '@/utils'
import newsApi from '@/api/news'
export default {
  components: {
  },
  data() {
    return {
      loading: false,
      list: [],
      page: {
        index: 1,
        size: 10,
        total: 0
      }
    }
  },
  filters: {
    summary: function(val, maxLength=100, replaceChar='...') {
      if (!val) {return ''}
      let str = val.replace(/<.*?>|&nbsp;|&emsp;/g,"")
      if (str.length > maxLength) {
        str = str.substr(0, maxLength) + replaceChar
      }
      return str
    }
  },
  methods: {
    getData() {
      this.loading = true
      let wheres = [
        {name: 'NewsType', value: '1', displayType: 'select'}
        // {name: 'DailyRecommend', value: '1', displayType: 'select'}
      ];
      newsApi.getPageData({
        page: this.page.index,
        rows: this.page.size,
        order: 'desc',
        sort: 'CreateDate',
        wheres: JSON.stringify(wheres)
      }).then(res => {
        this.loading = false
        res.rows.forEach(item => {
          let images = item.ImageUrl ? item.ImageUrl.split(',') : []
          if (images.length) {
            let url = images[0]
            let isUrl = util.isUrl(url)
            item.ex_ImageUrl = isUrl ? url : setting.baseUrl.img + '/' + url
          }
        })
        this.list = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.loading = false
      })
    },
    onPageChange(val) {
      this.getData()
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    padding: 30px 0;
  }
  /deep/ .el-pagination {
    position: relative;
    left: -5px;
    padding: 20px;
    background-color: #fff;
    &.is-background .btn-next, &.is-background .btn-prev, &.is-background .el-pager li {
      border-radius: 0;
      min-width: 38px;
    }
    .el-pager li {
      padding: 0 15px;
      height: 38px;
      line-height: 38px;
      border-radius: 0;
    }
    button, span:not([class*=suffix]) {
      height: 38px;
      line-height: 38px;
      border-radius: 0;
    }
  }
  
</style>
