import request from '@/utils/request'

export function getPageData(data) {
  return request({
    url: '/news/GetPageData',
    method: 'post',
    data
  })
}

export default {
  getPageData
}
